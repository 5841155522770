var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "homeProcess" }, [
    _c("div", { staticClass: "navigation" }, [
      _c(
        "div",
        [
          _c("Row", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tjdw.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(2)
                      },
                    },
                  }),
                  _c("p", [_vm._v("团检单位")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tjdd.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(5)
                      },
                    },
                  }),
                  _c("p", [_vm._v("团检订单")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tjry.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(4)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检人员")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tdsp.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(3)
                      },
                    },
                  }),
                  _c("p", [_vm._v("合同审批")]),
                ]),
              ]),
            ]),
          ]),
          _c("Row", [
            _c("div", { staticClass: "container regist" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/bm.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(1)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检登记")]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "square_brackets" }),
      _c(
        "div",
        { staticClass: "doc" },
        [
          _c("Row", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/cj.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(6)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检诊台")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/zj.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(7)
                      },
                    },
                  }),
                  _c("p", [_vm._v("医生总检")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/shbg.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(8)
                      },
                    },
                  }),
                  _c("p", [_vm._v("报告审核")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/dybg.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(9)
                      },
                    },
                  }),
                  _c("p", [_vm._v("体检报告")]),
                ]),
              ]),
              _c("div", { staticClass: "arrow" }, [
                _c("div", { staticClass: "arrow-body" }),
                _c("div", { staticClass: "arrow-footer" }),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "square_left" }),
      _c(
        "div",
        [
          _c("Row", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/dwbg.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(10)
                      },
                    },
                  }),
                  _c("p", [_vm._v("单位报告")]),
                ]),
              ]),
            ]),
          ]),
          _c("Row", [
            _c("div", { staticClass: "container healthy-regist" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "u-select" }, [
                  _c("img", {
                    staticClass: "rect",
                    attrs: { src: "/big_screen/images/tj.png" },
                    on: {
                      click: function ($event) {
                        return _vm.goto(11)
                      },
                    },
                  }),
                  _c("p", [_vm._v("查询统计")]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }